<template>
  <div>
<!--    <div class="d-flex">-->
<!--      <h4 class="mb-5" style="line-height: 2;">{{ $t("payments_summary") }} ({{ currencyName }})</h4>-->
<!--      <router-link to="/" class="btn btn-primary mr-3 ml-3" style="height: fit-content;">-->
<!--        {{$t('details')}}-->
<!--      </router-link>-->
<!--    </div>-->

    <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    dataLine: null,
    currencyName: null,
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    dataLine: function (val) {
      if(val){
        this.getData();

      }
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
    methods:{
      getData(){
          this.chartData.labels = this.dataLine.labels;
          this.chartData.datasets = [];
          this.chartData.datasets.push(
              {
                  label: this.$t('income_report.amount'),
                  backgroundColor: "#2980b9",
                  data: this.dataLine  ? this.dataLine.data : [],
              }
          );
      }
    },
  mounted() {
      this.getData();
  },
};
</script>
